const PASSWORD_REGEX =
  /^(?!.*[\s])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])[a-zA-Z0-9^$*.[\]{}()?\-"!@#%&/,><':;|_~`]{8,99}/;

const validate = (value: string | undefined, regex: RegExp) => {
  if (!value) {
    return false;
  }

  return regex.test(value);
};

const validator = {
  validPassword: (password: string | undefined) =>
    validate(password, PASSWORD_REGEX),
};

export default validator;
