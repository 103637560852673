import { Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';

export const useExpiryDate = () => {
  const [expiryDate, setExpiryDate] = useState<Dayjs | null>(null);
  const [expiryDateError, setExpiryDateError] = useState('');

  const isValid = useCallback(() => {
    if (expiryDate && expiryDate.isValid()) {
      return true;
    } else {
      setExpiryDateError('Please enter a valid date');
      return false;
    }
  }, [expiryDate]);

  const onFocus = useCallback(() => {
    setExpiryDateError('');
  }, []);

  const onChange = useCallback((date: Dayjs | null) => {
    setExpiryDate(date);
    setExpiryDateError(date !== null ? '' : 'Please enter a valid date');
  }, []);

  const onBlur = useCallback(() => {
    if (!isValid) {
      setExpiryDateError('Please enter a valid date');
    }
  }, [isValid]);

  return {
    isExpiryValid: isValid,
    expiryDate,
    expiryDateError,
    setExpiryDate,
    onExpiryDateFocus: onFocus,
    onExpiryDateBlur: onBlur,
    onExpiryChange: onChange,
  };
};
