import { useGetGeneticsPriceQuery, useGetSMEDiscountsQuery } from 'common/api';
import Navigation from 'pages/Navigation';
import SMEDiscountsTable from './SMEDIscountsTable';
import { Button, Modal } from '@mui/material';
import SMECreateDiscountModal from './SMECreateDiscountModal';
import { useState } from 'react';

export default function SMEDiscountsScreen() {
  const { data: smeDiscounts } = useGetSMEDiscountsQuery();
  const { data: geneticsPrice } = useGetGeneticsPriceQuery();

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="fullPageContainer">
      <Navigation />

      <Button
        variant="contained"
        className="self-end mt-standard ml-standard"
        onClick={() => setShowModal(true)}
      >
        Create
      </Button>

      {smeDiscounts && <SMEDiscountsTable discounts={smeDiscounts} />}

      {geneticsPrice && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <SMECreateDiscountModal
            geneticsPrice={geneticsPrice}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      )}
    </div>
  );
}
