import {
  useCreateDiscountMutation,
  useDeactivateCouponMutation,
  useDeactivateCouponsForBatchIdMutation,
  useDeactivateCouponUsersMutation,
  useGetDiscountsQuery,
} from '../../common/api';
import 'pages/login/login.css';
import DiscountsTable from './DiscountsTable';
import { Button, DialogContent, Grid, Modal } from '@mui/material';
import CreateDiscounModal from './CreateDiscountModal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CouponsModal from './CouponsModal';
import { DiscountCode } from './types';
import { useSearchParams } from 'react-router-dom';
import DiscountsSearchForm from './DiscountsSearchForm';
import Navigation from 'pages/Navigation';

export default function DiscountsScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const batchId = useMemo(() => {
    return searchParams.get('batchId') ?? '';
  }, [searchParams]);

  const { data: discounts = [] } = useGetDiscountsQuery({ batchId });

  const [selectedDiscount, setSelectedDiscount] = useState<DiscountCode>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [apiCouponError, setApiCouponError] = useState<string>();

  const justCreatedDiscount = useRef(false);

  const [createDiscount] = useCreateDiscountMutation();
  const [deactivateCoupon] = useDeactivateCouponMutation();
  const [deactivateCouponUsers] = useDeactivateCouponUsersMutation();
  const [deactivateCouponsForBatch, { isLoading: deactivatingCoupons }] =
    useDeactivateCouponsForBatchIdMutation();

  const onCreatePressed = useCallback(
    (
      batchId: string,
      discountType: string,
      numberOfCoupons: string,
      percentValue: number,
      unlimited: boolean,
      coupon: string,
      repeatable: boolean,
      numberOfUses: string,
      expiryDate: string | null,
      deactivateUserAfterExpiry: boolean
    ) => {
      setApiCouponError(undefined);

      createDiscount({
        batchId,
        discountType,
        numberOfCoupons: parseInt(numberOfCoupons),
        percentValue,
        unlimited,
        coupon,
        repeatable,
        numberOfUses: repeatable ? parseInt(numberOfUses) : null,
        expiryDate,
        deactivateUserAfterExpiry,
      })
        .unwrap()
        .then(() => {
          setShowCreateModal(false);
          justCreatedDiscount.current = true;
        })
        .catch((err) => {
          if (err.data.couponError) {
            setApiCouponError(err.data.error);
          }
        });
    },
    [createDiscount]
  );

  const onDeactivateCouponPressed = useCallback(
    (id: number) => {
      deactivateCoupon({ id });
    },
    [deactivateCoupon]
  );

  const onDeactivateCouponUsersPressed = useCallback(
    (id: number) => {
      deactivateCouponUsers({ id });
    },
    [deactivateCouponUsers]
  );

  useEffect(() => {
    if (justCreatedDiscount.current) {
      justCreatedDiscount.current = false;
      setSelectedDiscount(discounts[0]);
    }
  }, [discounts]);

  const modal = useMemo(() => {
    if (!selectedDiscount) {
      return <div></div>;
    }
    const { batchId } = selectedDiscount;
    return (
      <CouponsModal
        batchId={batchId}
        onDeactivatePressed={onDeactivateCouponPressed}
        onDeactivateUsersPressed={onDeactivateCouponUsersPressed}
        disableAllLoading={deactivatingCoupons}
        onDisableAllPressed={(batchId) =>
          deactivateCouponsForBatch({ batchId })
        }
      />
    );
  }, [
    deactivateCouponsForBatch,
    deactivatingCoupons,
    onDeactivateCouponPressed,
    onDeactivateCouponUsersPressed,
    selectedDiscount,
  ]);

  return (
    <div className="fullPageContainer">
      <Navigation />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          className="marginLeft20 marginTop20"
          onClick={() => setShowCreateModal(true)}
        >
          Create discount
        </Button>
      </Grid>

      <DiscountsSearchForm
        onSearchPressed={(batchId) => setSearchParams({ batchId })}
        batchId={batchId}
      />

      <DiscountsTable
        discounts={discounts}
        setSelectedDiscount={setSelectedDiscount}
      />

      <Modal open={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <DialogContent>
          <CreateDiscounModal
            onCreatePressed={onCreatePressed}
            apiCouponError={apiCouponError}
          />
        </DialogContent>
      </Modal>

      <Modal
        open={selectedDiscount !== undefined}
        onClose={() => setSelectedDiscount(undefined)}
      >
        <DialogContent>{modal}</DialogContent>
      </Modal>
    </div>
  );
}
