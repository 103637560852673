import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getCouponsCSV, useGetCouponsQuery } from 'common/api';
import { useMemo, useState } from 'react';
import { Coupon } from './types';
import moment from 'moment';

export default function CouponsModal(props: Props) {
  const { data = [] } = useGetCouponsQuery({ batchId: props.batchId });

  const {
    batchId,
    onDeactivatePressed,
    onDeactivateUsersPressed,
    onDisableAllPressed,
    disableAllLoading,
  } = props;
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [csvLoading, setCSVLoading] = useState(false);

  const columns = useMemo<GridColDef<Coupon>[]>(() => {
    return [
      {
        field: 'batchId',
        headerName: 'Batch ID',
      },
      {
        field: 'coupon',
        headerName: 'Coupon',
        flex: 1,
        renderCell: (params) => {
          const {
            row: { coupon, batchId },
          } = params;

          return (
            <span
              className="clickable"
              onClick={() => {
                window.open(
                  `/users?batchId=${batchId}&coupon=${coupon}`,
                  '_blank'
                );
              }}
            >
              {coupon}
            </span>
          );
        },
      },
      {
        field: 'unlimited',
        headerName: 'Unlimited',
        flex: 1,
        valueGetter: (_value, row) =>
          row.unlimited && row.numberOfUses === null ? 'Yes' : 'No',
      },
      {
        field: 'number_of_uses',
        headerName: 'Number of uses',
        flex: 1,
        valueGetter: (_value, row) =>
          row.numberOfUses !== null ? row.numberOfUses : 'N / A',
      },
      {
        field: 'expiryDate',
        headerName: 'Expiry date',
        flex: 1,
        valueGetter: (_value, row) =>
          row.expiryDate !== null
            ? moment(row.expiryDate).format('DD/MM/YYYY')
            : 'N / A',
      },
      {
        field: 'deactivateUsersAfterExpiry',
        headerName: 'Deactivate users',
        flex: 1,
      },
      { field: 'status', headerName: 'Status', flex: 1 },
      {
        field: 'action',
        type: 'actions',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => {
          const {
            row: { deactivated, id },
          } = params;

          return deactivated ? (
            <span>Deactivated</span>
          ) : (
            <LoadingButton
              variant="contained"
              onClick={() => onDeactivatePressed(id)}
            >
              DEACTIVATE
            </LoadingButton>
          );
        },
      },
      {
        field: 'userAction',
        type: 'actions',
        headerName: 'User Action',
        flex: 1.75,
        renderCell: (params) => {
          const {
            row: { deactivatedUsers, id, status },
          } = params;

          if (status === 'not used') {
            return <span>Not used</span>;
          }

          return deactivatedUsers ? (
            <span>Users deactivated</span>
          ) : (
            <LoadingButton
              variant="contained"
              onClick={() => onDeactivateUsersPressed(id)}
            >
              DEACTIVATE USERS
            </LoadingButton>
          );
        },
      },
    ];
  }, [onDeactivatePressed, onDeactivateUsersPressed]);

  const confirmationDialog = useMemo(() => {
    return (
      <Dialog open={showDeactivateDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          This will disable all coupons and all users who have signed up using
          any of these coupons. Are you sure you want to continue?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onDisableAllPressed(batchId);
              setShowDeactivateDialog(false);
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setShowDeactivateDialog(false)}>No</Button>
        </DialogActions>
      </Dialog>
    );
  }, [batchId, onDisableAllPressed, showDeactivateDialog]);

  const disableTheDisableAllButton = useMemo(() => {
    const index = data.findIndex(
      (item) =>
        !item.deactivated || (item.status === 'used' && !item.deactivatedUsers)
    );

    return index < 0;
  }, [data]);

  return (
    <div className="topModalContainer">
      <div className="discountCoupons">
        <div className="disableAllButtonContainer">
          <strong>Click on a coupon to see the users that have used it</strong>

          <div>
            <LoadingButton
              variant="contained"
              className="marginRight20"
              onClick={() => {
                setCSVLoading(true);
                getCouponsCSV(batchId).finally(() => setCSVLoading(false));
              }}
              loading={csvLoading}
            >
              CSV
            </LoadingButton>

            <LoadingButton
              variant="contained"
              onClick={() => setShowDeactivateDialog(true)}
              loading={disableAllLoading}
              disabled={disableTheDisableAllButton}
            >
              DISABLE ALL
            </LoadingButton>
          </div>
        </div>

        <DataGrid
          sx={{ marginBottom: '10px' }}
          rows={data}
          columns={columns}
          hideFooter={data.length < 10}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        />

        {confirmationDialog}
      </div>
    </div>
  );
}

interface Props {
  batchId: string;

  onDeactivatePressed: (couponId: number) => void;
  onDeactivateUsersPressed: (couponId: number) => void;
  onDisableAllPressed: (batchId: string) => void;
  disableAllLoading: boolean;
}
