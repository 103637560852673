import { apiClient } from 'common/client';
import { SearchFormData, User } from '../pages/users/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Coupon, DiscountCode } from 'pages/discounts/types';
import { AxiosError } from 'axios';
import { InvestorUser, SMEDiscount } from 'pages/sme/types';

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: ['Users', 'Discounts', 'Coupons', 'SMEDiscount', 'InvestorUser'],

  endpoints: (build) => ({
    searchUsers: build.query<{ users: User[]; count: number }, SearchFormData>({
      queryFn: async (args) => {
        const { email, discountCode, batchId, name, barcode, page } = args;
        const {
          data: { users, count },
        } = await apiClient.get<{ users: User[]; count: number }>(
          `/users?email=${email}&discountCode=${discountCode}&batchId=${batchId}&name=${name}&barcode=${barcode}&page=${page}`
        );

        return { data: { users, count } };
      },
    }),

    getUserDetails: build.query<User, string>({
      queryFn: async (uuid: string) => {
        const {
          data: { user },
        } = await apiClient.get<{ user: User }>(`/users/${uuid}`);

        return { data: user };
      },
      providesTags: (result) =>
        result
          ? [{ type: 'Users', id: result.username }]
          : [{ type: 'Users', id: 'error' }],
    }),

    disableUser: build.mutation<{}, string>({
      queryFn: async (uuid: string) => {
        await apiClient.get<{ user: User }>(`/users/${uuid}/disable`);

        return { data: {} };
      },

      invalidatesTags: (_result, _error, uuid) => [{ type: 'Users', id: uuid }],
    }),

    enableUser: build.mutation<{}, string>({
      queryFn: async (uuid: string) => {
        await apiClient.get<{ user: User }>(`/users/${uuid}/enable`);

        return { data: {} };
      },

      invalidatesTags: (_result, _error, uuid) => [{ type: 'Users', id: uuid }],
    }),

    updateUserKitAtLab: build.mutation<{}, { id: number; uuid: string }>({
      queryFn: async ({ id }) => {
        await apiClient.post<{ user: User }>(`/users/${id}/updateKitAtLab`, {});

        return { data: {} };
      },

      invalidatesTags: (_result, _error, { uuid }) => [
        { type: 'Users', id: uuid },
      ],
    }),

    changePassword: build.mutation<{}, { uuid: string; password: string }>({
      queryFn: async (data) => {
        const { uuid, password } = data;

        await apiClient.post<{ user: User }>(`/users/${uuid}/changePassword`, {
          password,
        });

        return { data: {} };
      },

      invalidatesTags: (_result, _error, { uuid }) => [
        { type: 'Users', id: uuid },
      ],
    }),

    updateGeneticsWebsite: build.mutation<
      unknown,
      { userId: number; expirationDate: string | null; uuid: string }
    >({
      queryFn: async ({ userId, expirationDate }) => {
        await apiClient.post(`/users/${userId}/geneticsWebsite`, {
          expirationDate,
        });

        return { data: {} };
      },

      invalidatesTags: (_result, _error, { uuid }) => [
        { type: 'Users', id: uuid },
      ],
    }),

    getDiscounts: build.query<DiscountCode[], { batchId?: string }>({
      queryFn: async ({ batchId }) => {
        const {
          data: { discountCodes },
        } = await apiClient.get<{ discountCodes: DiscountCode[] }>(
          `/discounts?batchId=${batchId}`
        );

        return { data: discountCodes };
      },

      providesTags: [{ type: 'Discounts', id: 'LIST' }],
    }),

    getCoupons: build.query<
      Coupon[],
      {
        batchId: string;
      }
    >({
      queryFn: async (args) => {
        const { batchId } = args;
        const {
          data: { coupons },
        } = await apiClient.get<{ coupons: Coupon[] }>(
          `/discounts/coupons?batchId=${batchId}`
        );

        return { data: coupons };
      },

      providesTags: (result, _error, queryArgs) => [
        { type: 'Discounts', id: queryArgs.batchId },
        ...result!.map((item) => ({ type: 'Coupons' as const, id: item.id })),
      ],
    }),

    deactivateCoupon: build.mutation<{}, { id: number }>({
      queryFn: async (args) => {
        await apiClient.get(`discounts/coupons/${args.id}/deactivate`);
        return {
          data: {},
        };
      },

      invalidatesTags: (_result, _error, args) => [
        { type: 'Coupons', id: args.id },
      ],
    }),

    deactivateCouponUsers: build.mutation<{}, { id: number }>({
      queryFn: async (args) => {
        await apiClient.get(`discounts/coupons/${args.id}/deactivateUsers`);
        return {
          data: {},
        };
      },

      invalidatesTags: (_result, _error, args) => [
        { type: 'Coupons', id: args.id },
      ],
    }),

    deactivateCouponsForBatchId: build.mutation<{}, { batchId: string }>({
      queryFn: async (args) => {
        await apiClient.get(`/discounts/${args.batchId}/deactivateAll`);
        return {
          data: {},
        };
      },

      invalidatesTags: (_result, _error, args) => [
        { type: 'Discounts', id: args.batchId },
      ],
    }),

    createDiscount: build.mutation<
      {},
      {
        batchId: string;
        percentValue: number;
        discountType: string;
        unlimited: boolean;
        numberOfCoupons: number;
        coupon: string;
        repeatable: boolean;
        numberOfUses: number | null;
        expiryDate: string | null;
        deactivateUserAfterExpiry: boolean;
      }
    >({
      queryFn: async (args) => {
        try {
          await apiClient.post<{ user: User }>('/discounts/coupons', args);

          return { data: {} };
        } catch (error) {
          let err = error as AxiosError;

          return {
            error: {
              status: err.response!.status,
              data: err.response!.data,
            },
          };
        }
      },
      invalidatesTags: (_1, _2, args) => [
        { type: 'Discounts', id: 'LIST' },
        { type: 'Discounts', id: args.batchId },
      ],
    }),

    getAllBatchIds: build.query<string[], void>({
      queryFn: async () => {
        const { data } = await apiClient.get<string[]>(
          '/discounts/allBatchIds'
        );

        return { data };
      },

      providesTags: [{ type: 'Discounts', id: 'LIST' }],
    }),

    getSMEDiscounts: build.query<SMEDiscount[], void>({
      queryFn: async () => {
        const { data } = await apiClient.get<SMEDiscount[]>('/sme/coupons');

        return { data };
      },

      providesTags: [{ type: 'SMEDiscount', id: 'LIST' }],
    }),

    getGeneticsPrice: build.query<number, void>({
      queryFn: async () => {
        const {
          data: { price },
        } = await apiClient.get<{ price: number }>('/sme/geneticsPrice');

        return { data: price };
      },
    }),

    createSMEDiscount: build.mutation<
      unknown,
      {
        plan: 'piki' | 'paka';
        price: number;
        dashboardPrice?: number;
        geneticsPrice?: number;
      }
    >({
      queryFn: async (args) => {
        try {
          await apiClient.post('/sme/createDiscount', args);

          return { data: {} };
        } catch (error) {
          let err = error as AxiosError;

          return {
            error: {
              status: err.response!.status,
              data: err.response!.data,
            },
          };
        }
      },

      invalidatesTags: [{ type: 'SMEDiscount', id: 'LIST' }],
    }),

    getInvestorUsers: build.query<InvestorUser[], void>({
      queryFn: async () => {
        const {
          data: { users },
        } = await apiClient.get<{ users: InvestorUser[] }>('/sme/investors');

        return { data: users };
      },

      providesTags: [{ type: 'InvestorUser', id: 'LIST' }],
    }),

    grantAccess: build.mutation<unknown, string>({
      queryFn: async (username) => {
        await apiClient.post<{ users: InvestorUser[] }>(
          '/sme/investorsGrantAccess',
          { username }
        );

        return { data: {} };
      },

      invalidatesTags: [{ type: 'InvestorUser', id: 'LIST' }],
    }),

    revokeAccess: build.mutation<unknown, string>({
      queryFn: async (username) => {
        await apiClient.post<{ users: InvestorUser[] }>(
          '/sme/investorsRevokeAccess',
          { username }
        );

        return { data: {} };
      },

      invalidatesTags: [{ type: 'InvestorUser', id: 'LIST' }],
    }),
  }),
});

export const getUsersCSV = async (args: Omit<SearchFormData, 'page'>) => {
  const { email, discountCode, batchId, name } = args;

  const { data } = await apiClient.get(
    `/users/csv?email=${email}&discountCode=${discountCode}&batchId=${batchId}&name=${name}`,
    {
      responseType: 'blob',
    }
  );

  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'text/csv' })
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'users.csv');

  document.body.appendChild(link);
  link.click();
  link.remove();

  window.URL.revokeObjectURL(url);
  return {};
};

export const getCouponsCSV = async (batchId: string) => {
  const { data } = await apiClient.get(
    `/discounts/coupons/csv?batchId=${batchId}`,
    {
      responseType: 'blob',
    }
  );

  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'text/csv' })
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'coupons.csv');

  document.body.appendChild(link);
  link.click();
  link.remove();

  window.URL.revokeObjectURL(url);
  return {};
};

export const {
  useLazySearchUsersQuery,
  useGetUserDetailsQuery,
  useDisableUserMutation,
  useEnableUserMutation,
  useChangePasswordMutation,
  useUpdateGeneticsWebsiteMutation,

  useGetDiscountsQuery,
  useGetCouponsQuery,
  useCreateDiscountMutation,

  useDeactivateCouponMutation,
  useDeactivateCouponUsersMutation,
  useDeactivateCouponsForBatchIdMutation,

  useGetAllBatchIdsQuery,
  useUpdateUserKitAtLabMutation,

  useGetSMEDiscountsQuery,
  useGetGeneticsPriceQuery,
  useCreateSMEDiscountMutation,

  useGetInvestorUsersQuery,
  useRevokeAccessMutation,
  useGrantAccessMutation,
} = api;
