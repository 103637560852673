import { useCallback, useState } from 'react';

export default function useNumberCoupons() {
  const [numberCoupons, setNumberCoupons] = useState('1');
  const [numberCouponsError, setNumberCouponsError] = useState('');

  const [numberOfUses, setNumberOfUses] = useState('1');
  const [numberOfUsesError, setNumberOfUsesError] = useState('');

  const onFocus = useCallback((setError: (error: string) => void) => {
    return () => {
      setError('');
    };
  }, []);

  const onChange = useCallback((onChange: (text: string) => void) => {
    return (text: string) => {
      onChange(text);
    };
  }, []);

  const onBlur = useCallback(
    (
      value: string,
      setValue: (value: string) => void,
      setError: (value: string) => void
    ) => {
      return () => {
        if (isNaN(parseInt(value)) || parseInt(value) < 0) {
          setError('Please enter a value greater than 0');
        } else {
          setValue(value);
        }
      };
    },
    []
  );

  const isValid = useCallback(
    (value: string, setError: (error: string) => void) => {
      return () => {
        if (isNaN(parseInt(value)) || parseInt(value) < 0) {
          setError('Please enter a value greater than 0');
          return false;
        }
        return true;
      };
    },
    []
  );

  return {
    numberCoupons,
    numberCouponsError,
    setNumberCoupons,
    setNumberCouponsError,

    onNumberCouponsFocus: onFocus(setNumberCouponsError),
    onNumberCouponsChange: onChange(setNumberCoupons),
    onNumberCouponsBlur: onBlur(
      numberCoupons,
      setNumberCoupons,
      setNumberCouponsError
    ),
    isNumberOfCouponsValid: isValid(numberCoupons, setNumberCouponsError),

    numberOfUses,
    numberOfUsesError,
    setNumberOfUses,
    setNumberOfUsesError,

    onNumberUsesFocus: onFocus(setNumberOfUsesError),
    onNumberUsesChange: onChange(setNumberOfUses),
    onNumberUsesBlur: onBlur(
      numberOfUses,
      setNumberOfUses,
      setNumberOfUsesError
    ),
    isNumberOfUsesValid: isValid(numberOfUses, setNumberOfUsesError),
  };
}
